import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { Box, Flex } from '@rebass/emotion'

import {
  CurveBox,
  HeroHeadline,
  Paragraph,
  SubHeadline,
} from '../components/atoms'
import { ScrollSVGAnimator, SVGDropper } from '../components/atoms/'
import { Layout } from '../components/layouts/Layout'
import {
  ConstrainedWidthContainer,
  ProductSectionItem,
  ProductsGetInTouch,
} from '../components/molecules'
import {
  GetInTouch,
  PRODUCT_SECTION_IDS,
  ProductsNav,
  SEO,
} from '../components/organisms'
import DotsSvg from '../images/dots.svg'
import OnboardingLogo from '../images/Logo_Onboarding.svg'
import PayInLogo from '../images/Logo_PayIn.svg'
import PayOutLogo from '../images/Logo_Payout.svg'
import PayInLogoMobile from '../images/Logo_Prod1_PayIn_Mobile.svg'
import PayOutLogoMobile from '../images/Logo_Prod2_PayOut_Mobile.svg'
import OnboardingLogoMobile from '../images/Logo_Prod3_Onboard_Mobile.svg'
import RiskLogoMobile from '../images/Logo_Prod4_Risk_Mobile.svg'
import ReportingLogo from '../images/Logo_Prod5_Report.svg'
import ReportingLogoMobile from '../images/Logo_Prod5_Report_Mobile.svg'
import RiskLogo from '../images/Logo_Risk.svg'
import HeroSVG from '../images/lottie/Products_Graphic_Hero.json'
import OnboardingSVG from '../images/lottie/Products_Graphic_Onboarding.json'
import PayInSVG from '../images/lottie/Products_Graphic_PayIn.json'
import PayOutSVG from '../images/lottie/Products_Graphic_PayOut.json'
import ReportSVG from '../images/lottie/Products_Graphic_Report.json'
import RiskSVG from '../images/lottie/Products_Graphic_Risk.json'
import CustomersIcon from '../images/PayIn_Customers 1.png'
import FlexibleIcon from '../images/PayIn_Flexible 1.png'
import LoyaltyIcon from '../images/PayIn_Loyalty 1.png'
import RevenueIcon from '../images/PayIn_Revenue 1.png'
import AdvancedIcon from '../images/PayOut_Advanced 1.png'
import ComplexIcon from '../images/PayOut_Complex 1.png'
import CompliantIcon from '../images/PayOut_Compliant 1.png'
import GlobalIcon from '../images/Payout_Global 1.png'
import OnboardingImageMobile from '../images/Products_Graphic_Onboarding_Mobile.svg'
import PayInImageMobile from '../images/Products_Graphic_PayIn_Mobile.svg'
import PayOutImageMobile from '../images/Products_Graphic_PayOut_Mobile.svg'
import ReportingImageMobile from '../images/Products_Graphic_Report_Mobile.svg'
import RiskImageMobile from '../images/Products_Graphic_Risk_Mobile.svg'
import ComplianceIcon from '../images/Products_Icon_Compliance.svg'
import CustomerIcon from '../images/Products_Icon_Service.svg'
import RiskDisputeIcon from '../images/Risk_Dispute 1.png'
import RiskFraudIcon from '../images/Risk_Fraud 1.png'
import RiskSupportIcon from '../images/Risk_Support 1.png'
import RiskProprietaryIcon from '../images/RiskProprietary 1.png'
import { Mq } from '../styles/settings/theme'
import styled from '../styles/styled'

const HeroImageContainer = styled(Box)`
  height: 15rem;
  order: -1;

  ${Mq.sm} {
    min-height: 30rem;
    order: 0;
  }
`

const SectionSVGContainer = styled(Box)`
  display: none;
  margin: 2rem auto;

  ${Mq.sm} {
    display: block;
  }
`

const SectionLogo = styled.img`
  display: none;
  max-width: 100%;
  margin-bottom: 1rem;

  ${Mq.sm} {
    display: inline;
    max-height: initial;
    margin-top: 10rem;
  }
`

const SectionLogoMobile = styled.img`
  max-width: 100%;
  margin-top: 4rem;
  margin-bottom: 1rem;

  ${Mq.sm} {
    display: none;
  }
`

const SectionSubHeadline = styled(SubHeadline)`
  display: none;

  ${Mq.sm} {
    display: block;
  }
`

const MobileSectionSubHeadline = styled(Paragraph)`
  margin-bottom: 2rem;

  ${Mq.sm} {
    display: none;
    margin-bottom: 0;
  }
`

const SectionImageMobile = styled.img`
  margin: 0.5rem auto;

  ${Mq.sm} {
    display: none;
  }
`

const VerticalSectionImageMobile = styled.img`
  margin: 0.5rem 0;
  width: 100%;

  ${Mq.md} {
    display: none;
  }
`

const GridItemsContainer = styled(Box)`
  display: grid;
  max-width: 53rem;
  margin: auto;
  grid-template-columns: 1fr;
  grid-gap: 1rem 1rem;

  ${Mq.sm} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem 1.5rem;
  }
`

const OnboardingItemsContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem 1rem;
  padding: 0px;

  ${Mq.sm} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem 1.5rem;
    padding: 3rem;
  }

  ${Mq.md} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3rem 1.5rem;
  }
`

const ColoredBackgroundContainer = styled(Box)`
  background-color: ${props => props.theme.colors.lightBlue};
  position: relative;
`

const VerticalImageContainer = styled(Box)`
  display: none;

  ${Mq.md} {
    display: block;
  }
`

const Dots2 = styled(Box)`
  background-image: url('${DotsSvg}');
  position: absolute;
  width: 12rem;
  height: 18.1rem;
  top: -10%;
  right: -10.3rem;
  z-index: 0;
  display: none;
  transform: translateY(50%);

  ${Mq.sm} {
    display: block;
    z-index: 0;
  }
`

const OnboardingProductSectionItem = styled(ProductSectionItem)`
  padding: 4.5rem;
`

const CurveBoxTop = styled(CurveBox)`
  background-color: ${props => props.theme.colors.lightBlue};
  height: 50px;
  transform: rotate(180deg);
  top: -2.5rem;

  ${Mq.sm} {
    top: -5rem;
  }
`

const CurveBoxBottom = styled(CurveBox)`
  height: 50px;
  bottom: 0;
`

const ProductsPage = ({ data }) => {
  const payInItemsData = [
    {
      icon: FlexibleIcon,
      iconAlt: 'Flexible Payment Icon',
      headline: data.contentstackProductsPage.pay_in_item_1_title,
      description: data.contentstackProductsPage.pay_in_item_1_desc,
    },
    {
      icon: RevenueIcon,
      iconAlt: 'Revenue Optimization Icon',
      headline: data.contentstackProductsPage.pay_in_item_2_title,
      description: data.contentstackProductsPage.pay_in_item_2_desc,
    },
    {
      icon: CustomersIcon,
      iconAlt: 'Customers Pay Icon',
      headline: data.contentstackProductsPage.pay_in_item_3_title,
      description: data.contentstackProductsPage.pay_in_item_3_desc,
    },
    {
      icon: LoyaltyIcon,
      iconAlt: 'Rewards Program Icon',
      headline: data.contentstackProductsPage.pay_in_item_4_title,
      description: data.contentstackProductsPage.pay_in_item_4_desc,
    },
  ]

  const payOutItemsData = [
    {
      icon: AdvancedIcon,
      iconAlt: 'Advanced Payments Icon',
      headline: data.contentstackProductsPage.pay_out_item_1_title,
      description: data.contentstackProductsPage.pay_out_item_1_desc,
    },
    {
      icon: GlobalIcon,
      iconAlt: 'Global support Icon',
      headline: data.contentstackProductsPage.pay_out_item_2_title,
      description: data.contentstackProductsPage.pay_out_item_2_desc,
    },
    {
      icon: ComplexIcon,
      iconAlt: 'Complexity made simple icon',
      headline: data.contentstackProductsPage.pay_out_item_3_title,
      description: data.contentstackProductsPage.pay_out_item_3_desc,
    },
    {
      icon: CompliantIcon,
      iconAlt: 'Fully Compliant Icon',
      headline: data.contentstackProductsPage.pay_out_item_4_title,
      description: data.contentstackProductsPage.pay_out_item_4_desc,
    },
  ]

  const onboardingItemsData = [
    {
      icon: null,
      iconAlt: null,
      headline: data.contentstackProductsPage.onboarding_item_1_title,
      description: data.contentstackProductsPage.onboarding_item_1_desc,
    },
    {
      icon: null,
      iconAlt: null,
      headline: data.contentstackProductsPage.onboarding_item_2_title,
      description: data.contentstackProductsPage.onboarding_item_2_desc,
    },
    {
      icon: null,
      iconAlt: null,
      headline: data.contentstackProductsPage.onboarding_item_3_title,
      description: data.contentstackProductsPage.onboarding_item_3_desc,
    },
  ]

  const riskItemsData = [
    {
      icon: RiskProprietaryIcon,
      iconAlt: 'Proprietary Engines Icon',
      headline: data.contentstackProductsPage.risk_item_1_title,
      description: data.contentstackProductsPage.risk_item_1_desc,
    },
    {
      icon: RiskFraudIcon,
      iconAlt: 'Fraud Protection Icon',
      headline: data.contentstackProductsPage.risk_item_2_title,
      description: data.contentstackProductsPage.risk_item_2_desc,
    },
    {
      icon: RiskSupportIcon,
      iconAlt: '24/7 Support Icon',
      headline: data.contentstackProductsPage.risk_item_3_title,
      description: data.contentstackProductsPage.risk_item_3_desc,
    },
    {
      icon: RiskDisputeIcon,
      iconAlt: 'Dispute resolution Icon',
      headline: data.contentstackProductsPage.risk_item_4_title,
      description: data.contentstackProductsPage.risk_item_4_desc,
    },
  ]

  const reportingItemsData = [
    {
      icon: null,
      iconAlt: null,
      headline: data.contentstackProductsPage.reporting_item_1_title,
      description: data.contentstackProductsPage.reporting_item_1_desc,
    },
    {
      icon: null,
      iconAlt: null,
      headline: data.contentstackProductsPage.reporting_item_2_title,
      description: data.contentstackProductsPage.reporting_item_2_desc,
    },
    {
      icon: null,
      iconAlt: null,
      headline: data.contentstackProductsPage.reporting_item_3_title,
      description: data.contentstackProductsPage.reporting_item_3_desc,
    },
  ]

  const [currentSectionId, setCurrentSectionId] = useState(null)

  const [payInRef, payInInView] = useInView({
    threshold: 0.2,
  })
  const [payOutRef, payOutInView] = useInView({
    threshold: 0.2,
  })
  const [onboardingRef, onboardingInView] = useInView({
    threshold: 0.2,
  })
  const [riskRef, riskInView] = useInView({
    threshold: 0.2,
  })
  const [reportingRef, reportingInView] = useInView({
    threshold: 0.2,
  })

  const sectionStates = {
    [PRODUCT_SECTION_IDS.pay_in]: payInInView,
    [PRODUCT_SECTION_IDS.pay_out]: payOutInView,
    [PRODUCT_SECTION_IDS.onboarding]: onboardingInView,
    [PRODUCT_SECTION_IDS.risk]: riskInView,
    [PRODUCT_SECTION_IDS.reporting]: reportingInView,
  }

  useEffect(() => {
    setCurrentSectionId(
      Object.keys(sectionStates).find(key => sectionStates[key]),
    )
  }, [sectionStates])

  return (
    <Layout>
      <SEO
        title={data.contentstackProductsPage.title}
        description={data.contentstackProductsPage.seo_description}
      />

      <ConstrainedWidthContainer mb={[2]} pb={[5, 0]}>
        <Flex flexWrap="wrap" alignItems="center" mb={[3, 4]}>
          <Box width={[1, 6 / 12]}>
            <Box mb={[4]}>
              <HeroHeadline>
                {data.contentstackProductsPage.hero_headline}
              </HeroHeadline>
            </Box>

            <Box width={[1, 1, 1, 10 / 12]}>
              <Paragraph>
                {data.contentstackProductsPage.hero_description}
              </Paragraph>
            </Box>
          </Box>

          <HeroImageContainer width={[1, 6 / 12]}>
            <SVGDropper jsonObject={HeroSVG} loop={false} autoplay={true} />
          </HeroImageContainer>
        </Flex>
      </ConstrainedWidthContainer>

      <ProductsNav currentNavItemId={currentSectionId} />

      <ColoredBackgroundContainer
        id={PRODUCT_SECTION_IDS.pay_in}
        ref={payInRef}
      >
        <CurveBoxTop pt={2} mt={[0, '88px']} />
        <ConstrainedWidthContainer>
          <Box textAlign={['left', 'center']}>
            <SectionLogo src={PayInLogo} alt="Yapstone Pay In Logo" />
            <SectionLogoMobile
              src={PayInLogoMobile}
              alt="Yapstone Pay In Logo"
            />
            <Box width={[1, 11 / 12, 9 / 12, 8 / 12, 7 / 12]} mx={[0, 'auto']}>
              <SectionSubHeadline>
                {data.contentstackProductsPage.pay_in_sub_heading}
              </SectionSubHeadline>
            </Box>
          </Box>

          <MobileSectionSubHeadline>
            {data.contentstackProductsPage.pay_in_sub_heading}
          </MobileSectionSubHeadline>

          <SectionImageMobile
            src={PayInImageMobile}
            alt="Pay In Interface Graphic"
          />

          <SectionSVGContainer>
            <ScrollSVGAnimator thresholdPercent={0.35} jsonObject={PayInSVG} />
          </SectionSVGContainer>
          {/* <Dots2 /> */}
          <Box pt={4} mb={[3, 6]}>
            <GridItemsContainer>
              {payInItemsData.map(item => (
                <ProductSectionItem
                  key={item.headline}
                  headline={item.headline}
                  description={item.description}
                  icon={{ url: item.icon, altText: item.iconAlt }}
                  headlineColor="green"
                />
              ))}
            </GridItemsContainer>
          </Box>

          <ProductsGetInTouch
            text={data.contentstackProductsPage.pay_in_cta_desc}
          />
        </ConstrainedWidthContainer>
        <CurveBoxBottom />
      </ColoredBackgroundContainer>

      <div ref={payOutRef}>
        <ConstrainedWidthContainer id={PRODUCT_SECTION_IDS.pay_out}>
          <Box textAlign={['left', 'center']}>
            <SectionLogo src={PayOutLogo} alt="Yapstone Pay Out Logo" />
            <SectionLogoMobile
              src={PayOutLogoMobile}
              alt="Yapstone Pay Out Logo"
            />
            <Box
              width={[1, 11 / 12, 9 / 12, 8 / 12, 7 / 12]}
              mx={[0, 'auto']}
              mb={[0, 5]}
            >
              <SectionSubHeadline>
                {data.contentstackProductsPage.pay_out_sub_heading}
              </SectionSubHeadline>
            </Box>
          </Box>

          <MobileSectionSubHeadline>
            {data.contentstackProductsPage.pay_out_sub_heading}
          </MobileSectionSubHeadline>

          <Box pt={4} mb={[3]}>
            <GridItemsContainer>
              {payOutItemsData.slice(0, 2).map(item => (
                <ProductSectionItem
                  key={item.headline}
                  headline={item.headline}
                  description={item.description}
                  icon={{ url: item.icon, altText: item.iconAlt }}
                  headlineColor="aqua"
                />
              ))}
            </GridItemsContainer>
          </Box>

          <Box mb={[0, 5]}>
            <SectionImageMobile
              src={PayOutImageMobile}
              alt="Pay Out Interface Graphic"
            />
            <SectionSVGContainer>
              <ScrollSVGAnimator
                thresholdPercent={0.35}
                jsonObject={PayOutSVG}
              />
            </SectionSVGContainer>
          </Box>

          <Box mb={[3, 6]}>
            <GridItemsContainer>
              {payOutItemsData.slice(2, 4).map(item => (
                <ProductSectionItem
                  key={item.headline}
                  headline={item.headline}
                  description={item.description}
                  icon={{ url: item.icon, altText: item.iconAlt }}
                  headlineColor="aqua"
                />
              ))}
            </GridItemsContainer>
          </Box>

          <ProductsGetInTouch
            text={data.contentstackProductsPage.pay_out_cta_desc}
          />
          {/* <Dots2 /> */}
        </ConstrainedWidthContainer>
      </div>

      <ColoredBackgroundContainer
        id={PRODUCT_SECTION_IDS.onboarding}
        ref={onboardingRef}
      >
        <CurveBoxTop />
        <ConstrainedWidthContainer>
          <Box textAlign={['left', 'center']}>
            <SectionLogo src={OnboardingLogo} alt="Yapstone Onboarding Logo" />
            <SectionLogoMobile
              src={OnboardingLogoMobile}
              alt="Yapstone Onboarding Logo"
            />
            <Box width={[1, 11 / 12, 9 / 12, 8 / 12, 7 / 12]} mx={[0, 'auto']}>
              <SectionSubHeadline>
                {data.contentstackProductsPage.onboarding_sub_heading}
              </SectionSubHeadline>
            </Box>
          </Box>

          <MobileSectionSubHeadline>
            {data.contentstackProductsPage.onboarding_sub_heading}
          </MobileSectionSubHeadline>

          <SectionSVGContainer>
            <ScrollSVGAnimator
              thresholdPercent={0.35}
              jsonObject={OnboardingSVG}
            />
          </SectionSVGContainer>

          <SectionImageMobile
            src={OnboardingImageMobile}
            alt="Onboarding Interface Graphic"
          />

          <Box mb={[3, 6]}>
            <OnboardingItemsContainer>
              {onboardingItemsData.map(item => (
                <OnboardingProductSectionItem
                  key={item.headline}
                  headline={item.headline}
                  description={item.description}
                  icon={{ url: item.icon, altText: item.iconAlt }}
                  headlineColor="babyBlue"
                />
              ))}
            </OnboardingItemsContainer>
          </Box>

          <ProductsGetInTouch
            text={data.contentstackProductsPage.onboarding_cta_desc}
          />
        </ConstrainedWidthContainer>
        <CurveBoxBottom />
      </ColoredBackgroundContainer>

      <div ref={riskRef}>
        <ConstrainedWidthContainer id={PRODUCT_SECTION_IDS.risk}>
          <Box textAlign={['left', 'center']}>
            <SectionLogo src={RiskLogo} alt="Yapstone Risk Logo" />
            <SectionLogoMobile src={RiskLogoMobile} alt="Yapstone Risk Logo" />
            <Box
              width={[1, 11 / 12, 9 / 12, 8 / 12, 7 / 12]}
              mx={[0, 'auto']}
              mb={[0, 5]}
            >
              <SectionSubHeadline>
                {data.contentstackProductsPage.risk_sub_heading}
              </SectionSubHeadline>
            </Box>
          </Box>

          <MobileSectionSubHeadline>
            {data.contentstackProductsPage.risk_sub_heading}
          </MobileSectionSubHeadline>

          <Box mb={[3]}>
            <GridItemsContainer>
              {riskItemsData.slice(0, 2).map(item => (
                <ProductSectionItem
                  key={item.headline}
                  headline={item.headline}
                  description={item.description}
                  icon={{ url: item.icon, altText: item.iconAlt }}
                  headlineColor="blue"
                />
              ))}
            </GridItemsContainer>
          </Box>

          <Box mb={[0, 5]}>
            <SectionImageMobile
              src={RiskImageMobile}
              alt="Risk Interface Graphic"
            />
            <SectionSVGContainer>
              <ScrollSVGAnimator thresholdPercent={0.25} jsonObject={RiskSVG} />
            </SectionSVGContainer>
          </Box>

          <Box mb={[3, 6]}>
            <GridItemsContainer>
              {riskItemsData.slice(2, 4).map(item => (
                <ProductSectionItem
                  key={item.headline}
                  headline={item.headline}
                  description={item.description}
                  icon={{ url: item.icon, altText: item.iconAlt }}
                  headlineColor="blue"
                />
              ))}
            </GridItemsContainer>
          </Box>
          {/* <Dots2 /> */}

          <ProductsGetInTouch
            text={data.contentstackProductsPage.risk_cta_desc}
          />
        </ConstrainedWidthContainer>
      </div>

      <ColoredBackgroundContainer
        id={PRODUCT_SECTION_IDS.reporting}
        ref={reportingRef}
      >
        <CurveBoxTop />
        <ConstrainedWidthContainer>
          <Box textAlign={['left', 'center']} pt={[0, 6]}>
            <SectionLogo
              src={ReportingLogo}
              alt="Yapstone Reporting and Analysis Logo"
            />
            <SectionLogoMobile
              src={ReportingLogoMobile}
              alt="Yapstone Reporting and Analysis Logo"
            />

            <Box
              width={[1, 11 / 12, 11 / 12, 9 / 12]}
              mx={[0, 'auto']}
              mb={[0, 5]}
            >
              <SectionSubHeadline>
                {data.contentstackProductsPage.reporting_sub_heading}
              </SectionSubHeadline>
            </Box>
          </Box>

          <MobileSectionSubHeadline>
            {data.contentstackProductsPage.reporting_sub_heading}
          </MobileSectionSubHeadline>

          <Flex
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="center"
          >
            <VerticalImageContainer width={[1, 8 / 12]} mb={[0, 5]}>
              <SectionSVGContainer>
                <ScrollSVGAnimator
                  thresholdPercent={0.25}
                  jsonObject={ReportSVG}
                />
              </SectionSVGContainer>
            </VerticalImageContainer>

            <VerticalSectionImageMobile
              src={ReportingImageMobile}
              alt="Reporting Graphic"
            />

            <Box width={[1, 1, 4 / 12]}>
              {reportingItemsData.map(item => (
                <Box px={[0, 3]} key={item.headline} mb={[4]}>
                  <ProductSectionItem
                    headline={item.headline}
                    description={item.description}
                    icon={{ url: item.icon, altText: item.iconAlt }}
                    headlineColor="purple"
                  />
                </Box>
              ))}
            </Box>
          </Flex>

          <ProductsGetInTouch
            text={data.contentstackProductsPage.reporting_cta_desc}
          />
        </ConstrainedWidthContainer>
        <CurveBoxBottom />
      </ColoredBackgroundContainer>

      <ConstrainedWidthContainer>
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          pt={[4, 6, 7]}
          pb={[4, 4]}
        >
          <Box width={[1, 1, 5 / 12]} mb={[3, 3, 0]}>
            <img src={CustomerIcon} alt="Customer Icon" />
            <SubHeadline>
              {data.contentstackProductsPage.customer_title}
            </SubHeadline>
            <Paragraph>{data.contentstackProductsPage.customer_desc}</Paragraph>
          </Box>

          <Box width={[1, 1, 5 / 12]}>
            <img src={ComplianceIcon} alt="Compliance Icon" />
            <SubHeadline>
              {data.contentstackProductsPage.compliance_title}
            </SubHeadline>
            <Paragraph>
              {data.contentstackProductsPage.compliance_desc}
            </Paragraph>
          </Box>
        </Flex>
      </ConstrainedWidthContainer>

      <GetInTouch />
    </Layout>
  )
}

export default ProductsPage

export const query = graphql`
  query {
    contentstackProductsPage {
      title
      seo_description
      hero_description
      hero_headline
      onboarding_cta_desc
      onboarding_item_1_desc
      onboarding_item_1_title
      onboarding_item_2_desc
      onboarding_item_2_title
      onboarding_item_3_desc
      onboarding_item_3_title
      onboarding_sub_heading
      pay_in_cta_desc
      pay_in_item_1_desc
      pay_in_item_1_title
      pay_in_item_2_desc
      pay_in_item_2_title
      pay_in_item_3_desc
      pay_in_item_3_title
      pay_in_item_4_desc
      pay_in_item_4_title
      pay_in_sub_heading
      pay_out_cta_desc
      pay_out_item_1_desc
      pay_out_item_1_title
      pay_out_item_2_desc
      pay_out_item_2_title
      pay_out_item_3_desc
      pay_out_item_3_title
      pay_out_item_4_desc
      pay_out_item_4_title
      pay_out_sub_heading
      reporting_cta_desc
      reporting_item_1_desc
      reporting_item_1_title
      reporting_item_2_desc
      reporting_item_2_title
      reporting_item_3_desc
      reporting_item_3_title
      reporting_sub_heading
      risk_cta_desc
      risk_item_1_desc
      risk_item_1_title
      risk_item_2_desc
      risk_item_2_title
      risk_item_3_desc
      risk_item_3_title
      risk_item_4_desc
      risk_item_4_title
      risk_sub_heading
      customer_title
      customer_desc
      compliance_title
      compliance_desc
    }
  }
`
